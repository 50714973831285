import React from 'react';
import loadable from '@loadable/component';

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

const Layout = loadable(() => import('../components/Layout'));
const Head = loadable(() => import('../components/Head'));
const DonateMain = loadable(() => import('../components/partner-with-us/partner-with-us-main'));

const Donate = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Head title="Donate" />
        <DonateMain />
      </Layout>
    </ThemeProvider>
  );
};

export default Donate;
